body {
  background-size: 1920px 1080px;
  color: #00acc1;
}

.logo {
  text-align: center;
  font-size: 3em;
  color: #00acc1;
  font-weight: bold;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}

.outer {
  background-image: url("./clouds_colorful.png");
  background-repeat: no-repeat;
  display: table;
  position: absolute;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.form-fields {
  margin-left: auto;
  margin-right: auto;
  width: 20em;
}

.button {
  margin-top: 1em;
}

.errorMsg {
  margin-top: 0.5em;
  color: red;
}

.userInfo {
  color: #00acc1;
  font-size: 1.6em;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.uploadMsg {
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 1em;
}

.content {
  margin: 0em 2em 2em 2em;
}

.picPreview {
  width: 12em;
  height: 10em;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.picPreview img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin-right: auto;
  image-orientation: from-image;
  border: 2px solid black;
}

.pages {
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
  display: table;
  width: auto;
  border-spacing: 1em;
}

.pageButton {
  display: table-cell;
  margin-right: 1em;
}

table {
  width: 100%;
}

td {
  padding-right: 1em;
  padding-top: 1em;
}

.imageFull {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  background-color: black;
}

.imageFull img {
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  image-orientation: from-image;
  vertical-align: middle;
}

.albumSelector {
  float: right;
  margin-left: auto;
  width: 160px;
}

.albumRow {
  display: table;
  border-spacing: 1em 0.2em;
  margin-left: auto;
  margin-right: auto;
}

.albumPreview {
  width: 10em;
  height: 10em;
  display: table-cell;
  text-align: center;
}

.header {
  background-color: #00acc1;
  color: white;
  padding: 0.4em 2em 0.4em 2em;
  height: 2.4em;
  text-align: center;
}

.headerLogo {
  font-size: 2em;
  font-weight: bold;
  float: left;
}

.headerUser {
  font-size: 1.6em;
  float: right;
  margin-top: 0.1em;
  margin-right: 0.2em;
}

.headerAlbum {
  font-size: 1.6em;
  margin-top: 0.1em;
  display: inline;
}

.albumTitle {
  font-size: 1.6em;
  margin-top: 0.2em;
  text-align: center;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.albumPreviewOuter {
  display: inline;
}

.sharingUser {
}

.sharedUsers {
  margin-bottom: -1em;
}

.signature {
  font-size: 0.5em;
  font-family: serif;
  display: inline;
  margin-left: 0.4em;
}

.signature a {
  color: white;
}

.logo .signature {
  font-size: 0.3em;
  margin-top: -0.4em;
  display: block;
}

.logo .signature a {
  color: #00acc1;
}

.header a {
  color: white;
  text-decoration: none;
}

@media (max-width: 700px) {
  .albumRow {
    border-spacing: 0.2em 0em;
  }

  .albumPreview {
    width: 6em;
    height: 6em;
  }

  .pages {
    border-spacing: 0.1em;
  }

  .pageButton {
    margin-right: 0.1em;
  }

  .headerLogo {
    font-size: 1.4em;
  }

  .signature {
    display: block;
    margin-top: -0.2em;
  }
}
